@import 'src/styles/variables';

$placeholderColor: #b9b9b9;
$borderColor: #ebebeb;

.form-group {
  position: relative;

  &:global(.horizontal) {
    display: grid;
    grid-template-columns: 150px auto;
    grid-template-rows: minmax(32px, auto);
    grid-template-areas:
      'label title'
      'error error';
    align-items: center;
    gap: 0 10px;

    &:global(.noLabel) {
      grid-template-columns: 1fr;
      grid-template-rows: minmax(32px, auto);
      grid-template-areas:
        'title title'
        'error error';
    }

    label {
      grid-area: label;
      margin-bottom: 0;
      margin-right: 10px;
    }

    input {
      grid-area: title;
      height: 32px;
      border: 1px solid $tr;
      border-bottom: 1px solid $borderColor;
      width: 100%;
      padding: 0 8px;

      &::placeholder {
        color: $placeholderColor;
      }
    }

    & .title {
      grid-area: title;
      min-height: 32px;
      line-height: 32px;
      padding-left: 10px;
      padding-right: 15px;
      border: 1px solid $tr;
      border-bottom: 1px solid $borderColor;

      .link {
        word-break: break-word;
        line-height: 32px;
      }
    }

    .form-group__errorText {
      grid-area: error;
      margin-top: 0;
    }

    :global(.ErrorText) {
      grid-area: error;
    }

    &:global(.disabled) {
      color: #222222;
      .title {
        .link {
          color: #222222;
        }
      }
    }
  }

  &:global(.disabled) {
    input {
      background: #f6f6f600;
      border-bottom: 1px solid $borderColor;
      color: #828282;
      cursor: not-allowed;

      &:focus {
        border-color: #cbcbcb;
      }
    }

    textarea {
      color: #828282;
    }

    .title {
      color: #828282;
      cursor: not-allowed;
    }
  }

  &:global(.isTableView) {
    input {
      padding: 0 8px;
    }
    label {
      display: none;
    }
  }

  .maxCount {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    color: $text-hint;
  }

  & .title {
    font-size: 14px;
    line-height: 22px;
    color: #222222;
    overflow: hidden;
    word-break: break-all;

    a {
      color: $blue;
    }
    .link {
      color: $blue;
      cursor: pointer;
    }
  }

  label {
    color: $text-hint;
    font-size: 12px;
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
  }

  input {
    background: $white;
    border: 1px solid $borderColor;
    box-sizing: border-box;
    border-radius: 1px;
    height: 48px;
    width: 100%;
    padding-left: 14px;
    padding-right: 48px;
    font-size: 14px;
    color: $black;
    transition: border 0.1s;

    &:focus {
      outline: none;
      border: 1px solid $blue;
    }
  }

  textarea {
    background: $white;
    border: 1px solid $borderColor;
    box-sizing: border-box;
    border-radius: 1px;
    min-height: 100px;
    width: 100%;
    font-size: 14px;
    line-height: 26px;
    color: $black;
    transition: border 0.1s;
    padding: 13px 14px;
    resize: none;
    overflow: hidden;

    &:disabled {
      cursor: not-allowed;
    }

    &:global(.exactly) {
      border: 1px solid rgba(0, 0, 0, 0);
      border-bottom: 1px solid $borderColor;
      min-height: 32px;
      line-height: 32px;
      padding: 0px 0 0px 0;
      padding-left: 10px;
      padding-right: 15px;

      &::placeholder {
        line-height: 32px;
      }
    }

    &:focus {
      outline: none;
      border: 1px solid $blue;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $tr;
    }

    &::-webkit-scrollbar-thumb {
      background: #dddddd;
      border-radius: 15px;
    }

    &::placeholder {
      font-size: 14px;
      line-height: 22px;
      color: $placeholderColor;
    }
  }

  & .body {
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 18px;
      pointer-events: none;

      svg {
        vertical-align: middle;
      }
    }

    input {
      &::placeholder {
        color: $placeholderColor;
      }
    }
  }

  &_error {
    label {
      color: $red;
    }

    input {
      border: 1px solid $red;

      &:focus {
        border-color: $red;
      }
    }
    textarea {
      border: 1px solid $red;

      &:focus {
        border-color: $red;
      }
    }
  }

  &_success {
    label {
      color: $green;
    }

    input {
      border: 1px solid $green;

      &:focus {
        border-color: $green;
      }
    }
    textarea {
      border: 1px solid $green;

      &:focus {
        border-color: $green;
      }
    }
  }

  &__errorText {
    color: $red;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    line-height: 1.3;

    svg {
      color: $red;
      margin-right: 6px;
      flex-shrink: 0;
    }
  }

  &__errorSuccess {
    color: $green;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    line-height: 1.3;

    svg {
      color: $green;
      margin-right: 6px;
      flex-shrink: 0;
    }
  }

  &__link {
    position: absolute !important;
    top: 0;
    right: 0;
    font-size: 12px;
    line-height: 12px;
    text-align: right;

    a {
      color: $blue;
    }
  }
}

.label {
  color: $text-hint;
  font-size: 12px;
  margin-bottom: 8px;
  text-transform: uppercase;
  line-height: 12px;
  letter-spacing: 0.03em;
  display: flex;
  align-items: center;
}

:global(.isLocked) {
  .form-group {
    .title {
      color: $text-hint;
    }
    .link {
      color: $text-hint;
    }
  }
}
