@import 'src/styles/variables';

.card {
  margin: 0 auto;
  max-width: 774px;
  width: 100%;
  background: $white;
  display: block;
  pointer-events: all;
  box-shadow: 0 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: 1px;
}

.textarea {
  position: relative;
  .field {
    background: #fff;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 1px;
    min-height: 100px;
    width: 100%;
    font-size: 14px;
    line-height: 26px;
    color: #0a0a0a;
    transition: border 0.1s;
    padding: 13px 14px;
    resize: none;
    height: 184px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 28px;

  & .title {
    flex: 1 0 auto;
    max-width: calc(100% - 60px);
  }

  & .close {
    margin-left: 24px;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #f4f5f6;
    justify-content: center;
    align-items: center;

    svg {
      cursor: pointer;
      color: $black;
    }
  }
}

.content {
  padding: 0 28px;

  & .label {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #828282;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 0 8px;

    :global(.prompt) {
      margin-left: 0;
    }

    span {
      vertical-align: middle;
    }

    .marginNull {
      margin-left: 0;
    }
  }

  .grid {
    position: relative;

    &:global(._isDisabled) {
      cursor: not-allowed;
      .match,
      .list {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &:global(._isGeo) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 10px;
    }

    .loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      :global(.Loader) {
        background: $tr;
      }
    }

    .match {
      &_content {
        background: #fff;
        border: 1px solid #ddd;
        height: 184px;
        transition: border 0.1s;
        padding: 13px 14px;
        min-height: 100px;
        width: 100%;
        font-size: 14px;
        line-height: 26px;
        color: #0a0a0a;
        overflow: auto;

        .item {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
          &_title {
            margin-bottom: 12px;
          }
          &_checkboxes {
            padding-left: 20px;

            & > div {
              margin-bottom: 12px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

.tags {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 28px;
  margin-top: 16px;

  & .item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #e2e2e2;
    border-radius: 1px;
    height: 36px;
    padding: 0 16px;
    transition: background 0.1s;
    margin-left: 8px;
    cursor: pointer;

    &:global(.isDisabled) {
      pointer-events: none;
      cursor: default;
      border: 1px solid #828282;
      color: #828282;
      background: #e2e2e2;

      span,
      svg {
        color: #828282;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      background: #f6f6f6;
    }

    span {
      font-size: 14px;
      line-height: 22px;
      color: $black;
    }

    svg {
      margin-left: 8px;
      color: $black;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 32px 28px 28px;

  & > * {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.error {
  color: $red;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-top: 8px;

  a {
    color: #025dff;
  }

  svg {
    color: $red;
    margin-right: 6px;
    flex-shrink: 0;
  }
  span {
    overflow-wrap: anywhere;
  }

  &:global(.isLocation) {
    color: #222222;
  }

  .geo {
    color: $red;
  }
}

.link {
  color: $blue;
  cursor: pointer;
}
