@import 'src/styles/variables';

.error {
  color: #ff3636;
  font-size: 13px;
  line-height: 13px;
  display: flex;
  align-items: center;
  margin-top: 8px;

  svg {
    color: $red;
    margin-right: 6px;
    flex: none;
  }
}
