@import 'src/styles/variables';

.card {
  & :global(.react-tabs__tab-list) {
    pointer-events: all;
  }

  &__form {
    & > * {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      font-size: 13px;
      line-height: 20px;
      color: $black;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      margin: 0;
      appearance: none;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 24px;

    &:first-of-type {
      margin-top: 0;
    }

    &.card__row_nm {
      .card__col {
        margin-right: 0;
      }
    }

    &.card__row_input-with-select {
      .card__col {
        &:nth-child(1) {
          position: relative;
          right: -1px;
          z-index: 1;
          max-width: calc(100% - 115px);
        }

        &:nth-child(2) {
          max-width: 114px;
        }
      }
    }
  }

  &__col {
    margin-right: 20px;
    flex: 1 0 auto;
    max-width: calc(50% - 10px);

    &:last-child {
      margin-right: 0;
    }
  }

  h3 {
    margin-bottom: 28px;
  }
}

.cards {
  & > div {
    margin-bottom: 20px;
  }
}

.fakePrices {
  strong {
    color: #828282;
    font-size: 12px;
    display: block;
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
  }

  span {
    margin-right: 8px;
    font-size: 14px;
    line-height: 22px;
    color: #222222;
    text-transform: uppercase;
  }
}

.actions {
  padding: 0;
  background: $tr;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;

  button {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.container {
  @include campaignGrid;

  @include phone {
    grid-gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
}

.transparentWidget {
  opacity: 0.3;
}

.statisticsTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}
